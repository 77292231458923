.drawer{
    background-color: lightblue;
    width: 100%;
    height: 100%;
}
.navbar {
    padding: 0;
    margin-left: 22%;
}

.navbar button {
    list-style-type: none;
    padding: 4%;
    background-color: aquamarine;
    width: 70%;
    margin-bottom: 10%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border: none;
    font-size: larger;
}

.navbar button a{
    text-decoration: none;
    color: black;
}

.navbar button:hover{
    background-color: aqua;
}
.closeButton{
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 10px;
}

.icon{
    position: fixed;
}